import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import { getConifg } from '../App';

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        elevation1: {
          boxShadow: 'none',
        },
      },
    },
  },
});
/**
 * root: {
    width: '100%',
  },
  tableWrapper: {
    // maxHeight: 440,
    overflow: 'auto',
  },
  'muiPaper-elevation1': {
    'box-shadow': 'none',
  },
*/

interface column {
  readonly id: string;
  readonly label: string;
  readonly minWidth: number;
}

const columns: column[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 150,
  },
  {
    id: 'team1',
    label: 'First',
    minWidth: 150,
  },
  {
    id: 'team2',
    label: 'Second',
    minWidth: 150,
  },
  {
    id: 'team3',
    label: 'Third',
    minWidth: 150,
  },
  {
    id: 'team4',
    label: 'Fourth',
    minWidth: 150,
  },
  {
    id: 'team5',
    label: 'Fifth',
    minWidth: 150,
  },
  {
    id: 'team6',
    label: 'Sixth',
    minWidth: 150,
  },
  {
    id: 'total',
    label: 'Totals',
    minWidth: 150,
  },
];

interface managerRow {
  readonly name: string;
  readonly team1: presentableTeam;
  readonly team2: presentableTeam;
  readonly team3: presentableTeam;
  readonly team4: presentableTeam;
  readonly team5: presentableTeam;
  readonly team6: presentableTeam;
  readonly total: string;
}

interface manager {
  readonly Name: string;
  readonly Teams: presentableTeam[];
  readonly TotalString: string;
}

interface presentableTeam {
  readonly TeamID: number;
  readonly Name: string;
  readonly RecordString: string;
  readonly TeamLogoURL: string;
  readonly UpdatedTimeAgo: string;
  readonly TodaysGame: string;
}

function createData(manager: manager): managerRow {
  let name = manager.Name;
  let teams = manager.Teams;
  let team1 = teams[0];
  let team2 = teams[1];
  let team3 = teams[2];
  let team4 = teams[3];
  let team5 = teams[4];
  let team6 = teams[5];
  let total = manager.TotalString;
  return { name, team1, team2, team3, team4, team5, team6, total };
}


function renderColumn(c: column, value: any) {
  if (c.id === 'name' || c.id === 'total') {
    return value;
  } else {
    return (
      <div>
        <Avatar src={value.TeamLogoUrl} variant='square' />
        <p className='manager-row-text'>{value.Name}</p>
        <p className='manager-row-text'>{value.RecordString}</p>
        <p className='manager-row-text'>Game: {value.TodaysGame}</p>
        <p className='manager-row-text'>Updated: {value.UpdatedTimeAgo}</p>
      </div>
    );
  }
}

function renderManagerRows(rs: managerRow[]): any {
  return rs.map(r => (<TableRow hover role="checkbox" tabIndex={-1} key={r.name}>
    {columns.map(column => {
      const value = (r as any)[column.id];
      return (
        <TableCell key={column.id} style={(column.id !== 'name' && column.id !== 'total') ? {verticalAlign: 'top'} : {}}>
          {renderColumn(column, value)}
        </TableCell>
      );
    })}
  </TableRow>));
}

export default function Home() {
  const [err, setErr] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rows, setRows] = useState<managerRow[]>([]);

  useEffect(() => {
    getConifg().then((response) => response.json())
      .then(config => fetch(config.apiEndpoint + "/managers"))
      .then(result => {
        if (!result.ok) {
          throw result;
        } else {
          console.log("Success");
          console.log(result);
          return result.json();
        }
      })
      .then(result => {
        setRows([
          createData(result[0]),
          createData(result[1]),
          createData(result[2]),
          createData(result[3]),
          createData(result[4]),
          createData(result[5]),
          createData(result[6]),
          createData(result[7]),
          createData(result[8]),
          createData(result[9]),
          createData(result[10]),
          createData(result[11]),
          createData(result[12]),
          createData(result[13]),
          createData(result[14]),
          createData(result[15]),
          createData(result[16]),
          createData(result[17]),
          createData(result[18]),
        ]);
        setIsLoaded(true);
      })
      .catch(error => {
        console.log(error);
        error.json().then((body: any) => {
          console.log(body);
          setErr(body);
        });
        setErr(error);
      });
  }, []);


  return (
    <>
      {err != null && <div>Error: {err}</div>}
      {isLoaded == false && <div>Loading...</div>}
      {isLoaded == true &&
        <ThemeProvider theme={theme}>
          <Paper>
            <div>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderManagerRows(rows)}
                </TableBody>
              </Table>
            </div>
          </Paper></ThemeProvider>}
    </>
  );
}
